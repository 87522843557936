<template>
  <b-container v-can="'purchase.order.create'">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Nueva Orden Por Solicitud de Compra</h4>
          </template>
          <template v-slot:body>
            <b-form-group>
              <b-form-radio inline v-model="type" name="type" value="1"
                >Nueva Orden De Compra</b-form-radio
              >
              <b-form-radio inline v-model="type" name="type" value="2"
                >Orden De Compra A Partir De Solicitudes</b-form-radio
              >
            </b-form-group>
            <hr />
            <type-one v-if="type == 1" type="1" />
            <type-two v-if="type == 2" type="2" />
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import TypeOne from "./form/TypeOne.vue";
import TypeTwo from "./form/TypeTwo.vue";
import { mapActions } from "vuex";

export default {
  name: "Register",
  components: {
    TypeOne,
    TypeTwo,
  },
  async mounted() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    await this.listArea();
    await this.fechProviders();
    core.index();
  },
  methods: {
    ...mapActions({
      listArea: "area/listArea",
      fechProviders: "providers/fechProviders",
    }),
  },
  data() {
    return {
      type: 1,
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          html: "Órdenes de Compras",
          to: "/orders_requests/list",
        },
        {
          text: "Nueva Orden de compra",
          active: true,
        },
      ],
    };
  },
};
</script>
<style>
</style>
