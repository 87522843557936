<template>
  <div class="new-user-info">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group class="col-md-4" label-for="Área" label="Área">
            <ValidationProvider
              name="Área"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
              label="name"
                v-model="form.area_id"
                placeholder="Seleccionar..."
                :options="listAreas"
                :reduce="(area) => area.id"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                 :selectable="
                (item) => (item.id === 1 ? item.disabled : item)
              "
              >
                <template #no-options="{}">
                  Sin Resultados,<b-button
                    variant="link"
                    block
                    @click="$refs.createOrUpdate.showModal()"
                    >Agregar área</b-button
                  >
                </template>
                <template v-slot:option="option">
                <slot>
                  {{ option.name }}
                  <button
                    v-if="option.id === 1 ? true : false"
                    class="btn btn-link btn-block"
                    @click="$refs.createOrUpdate.showModal()"
                  >
                    Agregar área
                  </button></slot
                >
              </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group
            class="col-md-4"
            label-for="Solicitudes De Compras"
            label="Solicitudes De Compras"
          >
            <ValidationProvider
              name="Solicitudes De Compras"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                multiple
                v-model="form.purchase_requests"
                placeholder="Seleccionar..."
                :options="listrequest"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
                <template #no-options="{}"> Sin Resultados... </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <!-- PROVIDERS -->
            <b-form-group class="col-md-4" label-for="Proveedor" label="Proveedor">
            <ValidationProvider
              name="Proveedor"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
              :filterable="false"
                v-model="form.provider_id"
                placeholder="Seleccionar..."
                :options="providersOptions"
                 :reduce="(label) => label.value"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
                <template #no-options="{}">
                  Sin Resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
            <!-- DESCRIPTION -->
          <b-form-group class="col-md-12" label-for="Descripción" label="Descripción">
            <ValidationProvider
              name="Descripción"
              rules="required"
              v-slot="{ errors }"
            >
               <b-form-input
                v-model="form.description"
                type="text"
                placeholder="Descripción"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
              </b-form-input>
              <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <hr />
        <b-button variant="primary" type="submit">Guardar</b-button>
      </form>
    </ValidationObserver>
     <modals ref="createOrUpdate" />
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import modals from "@/components/core/modals/AreaModals.vue";
export default {
  name: "TypeTwo",
   components: {
    modals
  },
  props: {
    type: { default: 2 },
  },
  async created() {
    core.index();
    await this.listPurchaseRequests()
  },
  data() {
    return {
      form: {
        type: parseInt(this.type),
        area_id: "",
        purchase_requests: [],
        description:"",
        provider_id: ''
      },
    };
  },
  methods: {
    ...mapActions({
      listArea: "area/listArea",
      listPurchaseRequests: 'purchase/listPurchaseRequests'
    }),
    async onSubmit() {
      try {
        const payload = this.form;
        const res = await this.$store.dispatch(
          "orders/storePurchaseOrders",
          payload
        );
        core.showSnackbar("success", "Orden de Compra agregada Correctamente");
        this.$router.replace(`/orders_requests/show/${res.id}`);
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getPurchaseRequests : 'purchase/getPurchaseRequests',
       getProviders : "providers/getProviders"
    }),
    listAreas(){
      return this.getAreaList
    },
    listrequest(){
      return this.getPurchaseRequests.map((item) => ({
      id: item.id,
      label:
        item.correlative +
        " - " +
        item.user_applicant.fullname +
        item.created_at,
    }));
    },
     providersOptions(){
       if (this.getProviders) {
      return this.getProviders.map(({ id: value, business_name: label }) => ({
          value,
          label,
        }));
       }
    },
  },
};
</script>
