var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-user-info"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-4",attrs:{"label-for":"Área","label":"Área"}},[_c('ValidationProvider',{attrs:{"name":"Área","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","placeholder":"Seleccionar...","options":_vm.listAreas,"reduce":function (area) { return area.id; },"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v("Agregar área")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v(" Agregar área ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.area_id),callback:function ($$v) {_vm.$set(_vm.form, "area_id", $$v)},expression:"form.area_id"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-4",attrs:{"label-for":"Solicitudes De Compras","label":"Solicitudes De Compras"}},[_c('ValidationProvider',{attrs:{"name":"Solicitudes De Compras","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"multiple":"","placeholder":"Seleccionar...","options":_vm.listrequest},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.form.purchase_requests),callback:function ($$v) {_vm.$set(_vm.form, "purchase_requests", $$v)},expression:"form.purchase_requests"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-4",attrs:{"label-for":"Proveedor","label":"Proveedor"}},[_c('ValidationProvider',{attrs:{"name":"Proveedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"filterable":false,"placeholder":"Seleccionar...","options":_vm.providersOptions,"reduce":function (label) { return label.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados ")]}}],null,true),model:{value:(_vm.form.provider_id),callback:function ($$v) {_vm.$set(_vm.form, "provider_id", $$v)},expression:"form.provider_id"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label-for":"Descripción","label":"Descripción"}},[_c('ValidationProvider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Descripción"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('hr'),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Guardar")])],1)]}}])}),_c('modals',{ref:"createOrUpdate"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }