<template>
  <div class="new-user-info">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="justify-content-md-center">
          <!-- AREA -->
          <b-form-group class="col-md-5" label-for="Área" label="Área">
            <ValidationProvider
              name="Área"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="name"
                v-model="form.area_id"
                placeholder="Seleccionar..."
                :options="listAreas"
                :reduce="(area) => area.id"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                :selectable="(item) => (item.id === 1 ? item.disabled : item)"
              >
                <template #no-options="{}">
                  Sin Resultados,<b-button
                    variant="link"
                    block
                    @click="$refs.createOrUpdate.showModal()"
                    >Agregar área</b-button
                  >
                </template>
                <template v-slot:option="option">
                  <slot>
                    {{ option.name }}
                    <button
                      v-if="option.id === 1 ? true : false"
                      class="btn btn-link btn-block"
                      @click="$refs.createOrUpdate.showModal()"
                    >
                      Agregar área
                    </button></slot
                  >
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <!-- PROVIDERS -->
          <b-form-group
            class="col-md-6"
            label-for="Proveedor"
            label="Proveedor"
          >
            <ValidationProvider
              name="Proveedor"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                :filterable="false"
                v-model="form.provider_id"
                placeholder="Seleccionar..."
                :options="providersOptions"
                :reduce="(label) => label.value"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
                <template #no-options="{}"> Sin Resultados </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <!-- DESCRIPTION -->
          <b-form-group
            class="col-md-11"
            label-for="Descripción"
            label="Descripción"
          >
            <ValidationProvider
              name="Descripción"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="form.description"
                type="text"
                placeholder="Descripción"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <!-- TERMS AND CONDITIONS -->
          <b-form-group
            class="col-md-11"
            label-for="Terminos y condiciones"
            label="Terminos y condiciones"
          >
            <ValidationProvider
              name="Terminos y condiciones"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-textarea
                v-model="form.terms"
                placeholder="Ingresar terminos y condiciones"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <!-- producto -->
          <b-form-group
            class="col-md-5 mt-1"
            label-for="Producto"
            label="Producto"
          >
            <ValidationProvider
              name="Producto"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                v-model="product"
                placeholder="Busca un Producto por sku o nombre"
                :filterable="false"
                :options="productsOptions"
                @search="fetchProducts"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
                <template #no-options="{}">
                  Sin Resultados,<b-button
                    variant="link"
                    @click="$bvModal.show('bv-modal-product')"
                    >¿Desea registrar un producto?</b-button
                  >
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-4" label="Cantidad" label-for="Cantidad">
            <ValidationProvider
              name="Cantidad"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="quantity"
                type="number"
                placeholder="Ingresa cantidad de producto"
                :class="
                  errors.length > 0
                    ? ' is-invalid'
                    : '' || quantity <= 0
                    ? 'is-invalid'
                    : ''
                "
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span v-if="quantity <= 0"
                  >La cantidad no puede estar vacia, valor cero (0) o valores
                  negativos.
                </span>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-col class="col-md-2 my-auto">
            <b-button
              variant="primary"
              type="button"
              @click.prevent="addProduct"
              :disabled="quantity === '0' || quantity < 1 || !quantity"
              >Agregar producto</b-button
            >
          </b-col>

          <!-- Tabla de productos -->
          <b-col v-if="form.products.length">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title ml-md-5">Productos</h4>
              </template>
              <template v-slot:body>
                <b-row class="justify-content-md-center">
                  <b-col class="table-responsive" md="11">
                    <b-table hover :items="form.products" :fields="columns">
                      <template v-slot:cell(name)="data">
                        <span>{{ data.item.name }}</span>
                      </template>
                      <template v-slot:cell(quantity)="data">
                        <span>{{ data.item.quantity }}</span>
                      </template>
                      <template v-slot:cell(option)="data">
                        <b-button
                          variant=" iq-bg-danger"
                          @change="onChange($event)"
                          size="sm"
                          v-confirm="{ ok: (dialog) => remove(data.item.id) }"
                        >
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <hr />
        <b-button variant="primary" @click="onSubmit">Guardar</b-button>
      </form>
    </ValidationObserver>
    <b-modal size="lg" id="bv-modal-product" hide-footer>
      <template #modal-title> Agregar nuevo producto</template>
      <b-row>
        <register-product origin="1" v-on:hide="hide" />
      </b-row>
    </b-modal>
    <modals ref="createOrUpdate" />
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import RegisterProduct from "@/views/Admin/Products/forms/createOrUpdate.vue";
import modals from "@/components/core/modals/AreaModals.vue";
import { mapGetters } from "vuex";
export default {
  name: "TypeOne",
  components: {
    RegisterProduct,
    modals,
  },
  props: {
    type: { default: 1 },
  },
  async mounted() {
    core.index();
  },
  data() {
    return {
      form: {
        type: parseInt(this.type),
        area_id: "",
        products: [],
        description: "",
        provider_id: "",
        terms: "",
      },
      areas: [],
      productsSelect: [],
      product: "",
      quantity: null,

      columns: [
        { label: "Nombre", key: "name", class: "text-center" },
        { label: "Cantidad", key: "quantity", class: "text-center" },
        { label: "Opciones", key: "option", class: "text-center" },
      ],
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          html: "Órdenes de Compras",
          to: "/orders_requests/list",
        },
        {
          text: "Nueva Orden Por Solicitudes",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getProviders: "providers/getProviders",
    }),
    listAreas() {
      return this.getAreaList;
    },
    providersOptions() {
      if (this.getProviders) {
        return this.getProviders.map(({ id: value, business_name: label }) => ({
          value,
          label,
        }));
      }
    },
    productsOptions() {
      if (this.productsSelect.length) {
        return this.productsSelect.map(({ id: value, name: label }) => ({
          value,
          label,
        }));
      }
      return [];
    },
  },
  methods: {
    hide(res) {
      this.product = { value: res.id, label: res.name };
      this.$bvModal.hide("bv-modal-product");
    },
    addProduct() {
      if (!this.product) {
        core.showSnackbar("error", "Selecciona un producto");
        return;
      }
      if (!this.quantity) {
        core.showSnackbar("error", "Agrega la cantidad del producto");
        return;
      }
      this.form.products.push({
        id: this.product.value,
        name: this.product.label,
        quantity: this.quantity,
      });
      this.quantity = null;
      this.product = "";
    },
    async onSubmit() {
      try {
        const res = await this.$store.dispatch(
          "orders/storePurchaseOrders",
          this.form
        );
        core.showSnackbar("success", "Orden de Compra agregada Correctamente");
        this.$router.replace(`/orders_requests/show/${res.id}`);
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    // Funcion para buscar productos en base a la busqueda
    async fetchProducts(search, loading) {
      if (search && search.length > 2) {
        loading(true);
        const products = await this.$store.dispatch("products/listProducts", {
          search,
        });
        loading(false);
        this.productsSelect = products;
      } else {
        this.productsSelect = [];
      }
    },
    remove(item) {
      const index = this.form.products.findIndex((p) => p.id === item);
      this.form.products.splice(index, 1);
      core.showSnackbar("success", "Producto eliminado correctamente");
    },
  },
};
</script>
